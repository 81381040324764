<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="shadow box-item" style="min-height: 141px">
                <span style="font-size:18px;color: #363636;">Tool Administrasi</span>
            </div>
        </div>
        <div class="col-sm-3" style="min-height: 300px">
            <div class="shadow box-item">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-12" style="font-size:14px;align-self: center;">Akademik</div>
                </div>
                <div class="row" style="padding: 0px;" v-if="dataAkademik.length > 0">
                    <ul class="nav flex-column" style="width: 100%;">
                      <li class="nav-item" v-for="row in dataAkademik">
                        <a href="#" class="nav-link">
                          {{row.name}} <span class="float-right badge bg-primary" style="font-size: 100%">{{row.qty}}</span>
                        </a>
                      </li>
                    </ul>
                </div>
                <div v-else style="text-align: center;">Tidak ada data</div>
            </div>
        </div>
        <div class="col-sm-3" style="min-height: 300px">
            <div class="shadow box-item">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-12" style="font-size:14px;align-self: center;">Administrasi</div>
                </div>
                <div class="row" style="padding: 0px;" v-if="dataAdministrasi.length > 0">
                    <ul class="nav flex-column" style="width: 100%;">
                      <li class="nav-item" v-for="row in dataAdministrasi">
                        <a href="#" class="nav-link">
                          {{row.name}} <span class="float-right badge bg-primary" style="font-size: 100%">{{row.qty}}</span>
                        </a>
                      </li>
                    </ul>
                </div>
                <div v-else style="text-align: center;">Tidak ada data</div>
            </div>
        </div>
        <div class="col-sm-3" style="min-height: 300px">
            <div class="shadow box-item">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-12" style="font-size:14px;align-self: center;">Public Relation</div>
                </div>
                <div class="row" style="padding: 0px;" v-if="dataPr.length > 0">
                    <ul class="nav flex-column" style="width: 100%;">
                      <li class="nav-item" v-for="row in dataPr">
                        <a href="#" class="nav-link">
                          {{row.title}} <span class="float-right badge bg-primary" style="font-size: 100%">{{row.tanggal_mulai}}</span>
                        </a>
                      </li>
                    </ul>
                </div>
                <div v-else style="text-align: center;">Tidak ada data</div>
            </div>
        </div>
        <div class="col-sm-3" style="min-height: 300px">
            <div class="shadow box-item">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-6" style="font-size:14px;align-self: center;">Keuangan</div>
                </div>
                <div class="row" style="padding: 0px;" v-if="dataKeuangan">
                    <ul class="nav flex-column" style="width: 100%;">
                      <li class="nav-item">
                        <a href="#" class="nav-link">
                          Pemasukan <span class="float-right" style="font-weight: 600;font-size: 100%">{{formatMoney(dataKeuangan.nilai_masuk)}}</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="nav-link">
                          Pengeluaran <span class="float-right" style="font-weight: 600;font-size: 100%">{{formatMoney(dataKeuangan.nilai_keluar)}}</span>
                        </a>
                      </li>
                      <li class="nav-item" style="border-top: 1px gray solid;">
                        <a href="#" class="nav-link">
                          Total <span class="float-right" style="font-weight: 600;font-size: 100%">{{formatMoney(dataKeuangan.nilai_masuk-dataKeuangan.nilai_keluar)}}</span>
                        </a>
                      </li>
                    </ul>
                </div>
                <div v-else style="text-align: center;">Tidak ada data</div>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="shadow box-item" style="height:300px">
                <span style="font-size:18px">Aktifitas Unit</span>
                <canvas ref="chartscater" id="keaktifan-chart" height="300" style="display: block; width: 487px; height: 300px;" width="487" class="chartjs-render-monitor"></canvas>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="shadow box-item" style="height:300px">
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth, authFetch, formatCurrency} from '@/libs/hxcore';
    import "color-calendar/dist/css/theme-glass.css";
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';
    import Chart from 'chart.js';
    var chartKeaktifan;

    export default {
        name: 'ManajemenDashboard',
        components: {
            IconifyIcon
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                form: {
                    kbmActivity : ''
                },
                name : '',
                imgUrl : '',
                dataAkademik: [],
                dataAdministrasi: [],
                dataPr: [],
                dataKeuangan: []
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            console.log('user', info)
            if(info)
            {
              this.name = info.display_name;
              this.imgUrl = info.img_url;
            }
            else{
              this.imgUrl = '/dist/img/user.jpeg';
            }
            authFetch(`/dashboard/siswa_per_program`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataAkademik = js
                });
            authFetch(`/dashboard/data_administrasi`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataAdministrasi = js
                });
            authFetch(`/dashboard/data_pr`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataPr = js
                });
            authFetch(`/dashboard/labarugi`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataKeuangan = js
                });
        },
        methods: {
            formatMoney(data){
                return formatCurrency(data);
            },
        },
        mounted() {
            var ctx3 = document.getElementById('keaktifan-chart').getContext('2d')

            chartKeaktifan = new Chart(ctx3, {
                type: 'line',
                data: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April"
                    ],
                    datasets: [
                      {
                        label: "Data One",
                        backgroundColor: "#f87979",
                        borderColor: "#f87979",
                        data: [40, 20, 12, 39],
                        fill: false,
                      },
                      {
                        label: "Data Two",
                        backgroundColor: "rgb(75, 192, 192)",
                        borderColor: "rgb(75, 192, 192)",
                        data: [30, 12, 24, 31],
                        fill: false,
                      },
                      {
                        label: "Data Three",
                        backgroundColor: "#F1CC19",
                        borderColor: "#F1CC19",
                        data: [20, 11, 18, 25],
                        fill: false,
                      }
                    ]
                  },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        position: 'top',
                        display: false
                    },
                    title: {
                        display: true,

                    }
                }
            });
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 32px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .shadow-low {
        box-shadow: 0 0.3rem 0.3rem rgb(18 38 63 / 7%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 12px 22px;
        margin-bottom: 12px;
        /*height: 100%;*/
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .tool-box {
        /*width:48px;
        height: 48px;*/
        background: #C8372D;
        border-radius: 10px;
    }

    .callout-danger {
        background: #EBEBEB;
    }

    .callout.callout-danger {
        border-left: 8px #C8372D solid;
        border-radius: 10px;
    }

    .callout-warning {
        background: #EBEBEB;
    }

    .callout.callout-warning {
        border-left: 8px #FCA702 solid;
        border-radius: 10px;
    }

    .calendar__body.color-calendar {
        font-size: 0.7rem !important;
    }

    .box-item {
        width:100%;
        min-height: 262px;
        background:white;
        padding: 24px;
        padding-top: 24px;
        border-radius: 20px;
        margin-bottom: 12px;
    }

    .nav-link {
        padding: 0.5rem 0.5rem;
    }
</style>